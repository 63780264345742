import React, {
  createContext,
  useReducer,
  useEffect,
  useContext
} from 'react';
import validator from 'validator'
import PropTypes from 'prop-types';
import { WebSocketDataStore } from '../WebSocketData';
import ws from '../../webSocket';

const initialState = {
  email: 'unconfigured',
  valid: 'ogiltig epost',
};

const AlarmEmailStateStore = createContext(initialState);
export { AlarmEmailStateStore };
const { Provider } = AlarmEmailStateStore;
const reducer = (state, action) => {
  let valid = 'ogiltig epost';
  if (validator.isEmail(action.value.email)) {
    // This is a non-breaking space, hack to get an empty line
    valid = '\u00A0'
  }
  switch (action.type) {
    case 'UPDATE':
      return { email: action.value.email, valid: valid };
    case 'SAVE':
      if (validator.isEmail(action.value.email)) {
        ws.send({
          'update-config': {
            token: action.value.keycloakToken,
            tank: action.value.tank,
            'alarm-notification': { email: state.email }
          }
        });
        return { email: action.value.email, valid: 'Sparad!' };
      }
      else {
        return { email: action.value.email, valid: valid };
      }
    case 'WS_UPDATE':
      return { email: action.value.email, valid: valid };
    default:
      throw new Error();
  }
};


AlarmEmailStateProvider.propTypes = {
  children: PropTypes.any
};

export default function AlarmEmailStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wsConfigData } = useContext(WebSocketDataStore);

  useEffect(() => {
    if ('alarm-notification' in wsConfigData) {
      if ('email' in wsConfigData['alarm-notification']) {
        dispatch({
          type: 'WS_UPDATE',
          value: { email: wsConfigData['alarm-notification'].email }
        });
      }
    }
  }, [wsConfigData]);

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  );
}
