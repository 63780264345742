import React, { createContext, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WebSocketDataStore } from './WebSocketData';
import ws from '../webSocket';

const initialState = {
  range: []
};
const PhRangeStore = createContext(initialState);
export { PhRangeStore };
const { Provider } = PhRangeStore;
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      if (state.range !== action.value) {
        return { range: action.value.range };
      }
      break;
    case 'UPDATE_COMMIT':
      ws.send({
        'update-config': {
          token: action.value.keycloakToken,
          tank: action.value.tank,
          'ph0-alarm': { threshold: action.value.range }
        }
      });
      return { range: action.value.range };
    case 'WS_UPDATE':
      return { range: action.value.range };
    default:
      throw new Error();
  }
};

PhRangeProvider.propTypes = {
  children: PropTypes.any
};
export default function PhRangeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wsConfigData } = useContext(WebSocketDataStore);

  useEffect(() => {
    if ('ph0-alarm' in wsConfigData) {
      if ('threshold' in wsConfigData['ph0-alarm']) {
        dispatch({
          type: 'WS_UPDATE',
          value: { range: wsConfigData['ph0-alarm'].threshold }
        });
      }
    }
  }, [wsConfigData]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
