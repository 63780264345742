import React, { useContext, useState, useEffect } from 'react';
import TemperaturePlot from './TemperaturePlot';
import { WaterTemperatureStore } from '../../dataStore/WaterTemperature';
import { AirTemperatureStore } from '../../dataStore/AirTemperature';
import { PhSensorStore } from '../../dataStore/PhSensor';
import { FmSensorStore } from '../../dataStore/FmSensor';
import PropTypes from 'prop-types';

TemperatureGraph.propTypes = {
  type: PropTypes.string,
  height: PropTypes.number
};

export default function TemperatureGraph({ type, height }) {
  const waterTemperature = useContext(WaterTemperatureStore);
  const airTemperature = useContext(AirTemperatureStore);
  const phSensor = useContext(PhSensorStore);
  const fmSensor = useContext(FmSensorStore);

  // Plotly data prop
  const [waterTemperatureDataX, setWaterTemperatureDataX] = useState([]);
  const [waterTemperatureDataY, setWaterTemperatureDataY] = useState([]);
  useEffect(() => {
    setWaterTemperatureDataX(
      waterTemperature.temp.map(item => new Date(item.ts * 1000))
    );
    setWaterTemperatureDataY(waterTemperature.temp.map(item => item.t));
  }, [waterTemperature.temp]);

  const [airTemperatureDataX, setAirTemperatureDataX] = useState([]);
  const [airTemperatureDataY, setAirTemperatureDataY] = useState([]);
  useEffect(() => {
    setAirTemperatureDataX(
      airTemperature.temp.map(item => new Date(item.ts * 1000))
    );
    setAirTemperatureDataY(airTemperature.temp.map(item => item.t));
  }, [airTemperature.temp]);

  const [phSensorDataX, setPhSensorDataX] = useState([]);
  const [phSensorDataY, setPhSensorDataY] = useState([]);
  useEffect(() => {
    setPhSensorDataX(
      phSensor.ph.map(item => new Date(item.ts * 1000))
    );
    setPhSensorDataY(phSensor.ph.map(item => item.t));
  }, [phSensor.ph]);

  const [fmSensorDataX, setFmSensorDataX] = useState([]);
  const [fmSensorDataY, setFmSensorDataY] = useState([]);
  useEffect(() => {
    setFmSensorDataX(
      fmSensor.fm.map(item => new Date(item.ts * 1000))
    );
    setFmSensorDataY(fmSensor.fm.map(item => item.t));
  }, [fmSensor.fm]);

  const commonData = {
    type: 'scatter',
    mode: 'lines'
  };
  const waterTemperatureData = {
    ...commonData,
    x: waterTemperatureDataX,
    y: waterTemperatureDataY,
    marker: { color: 'blue' }
  };
  const airTemperatureData = {
    ...commonData,
    x: airTemperatureDataX,
    y: airTemperatureDataY,
    marker: { color: 'cyan' }
  };
  const phSensorData = {
    ...commonData,
    x: phSensorDataX,
    y: phSensorDataY,
    marker: { color: 'red' }
  };
  const fmSensorData = {
    ...commonData,
    x: fmSensorDataX,
    y: fmSensorDataY,
    marker: { color: 'green' }
  };

  // Plotly layout prop
  const commonLayout = {
    xaxis: {
      title: {
        text: 'Tid'
      }
    },
    yaxis: {
      title: {
        text: 'Temperatur (°C)'
      },
      range: [18, 28]
    },
    autosize: true,
    height: height
  };

   const phLayout = {
    xaxis: {
      title: {
        text: 'Tid'
      }
    },
    yaxis: {
      title: {
        text: 'pH'
      },
      range: [4, 14]
    },
    autosize: true,
    height: height
  };

   const fmLayout = {
    xaxis: {
      title: {
        text: 'Tid'
      }
    },
    yaxis: {
      title: {
        text: 'På/Av'
      },
      range: [-1, 2]
    },
    autosize: true,
    height: height
  };
  const waterTemperatureLayout = {
    ...commonLayout,
    title: 'Vattentemperatur'
  };
  const airTemperatureLayout = {
    ...commonLayout,
    title: 'Lufttemperatur'
  };
  const phSensorLayout = {
    ...phLayout,
    title: 'Vatten pH'
  };

  const fmSensorLayout = {
    ...fmLayout,
    title: 'Aktivitet för fiskmatar-motor'
  };

  return (
    <div>
      {type === 'water' && !waterTemperature.loading && (
        <TemperaturePlot
          data={[waterTemperatureData]}
          layout={waterTemperatureLayout}
        />
      )}
      {type === 'air' && !airTemperature.loading && (
        <TemperaturePlot
          data={[airTemperatureData]}
          layout={airTemperatureLayout}
        />
      )}
      {type === 'ph' && !phSensor.loading && (
        <TemperaturePlot
          data={[phSensorData]}
          layout={phSensorLayout}
        />
      )}
      {type === 'fm' && !fmSensor.loading && (
        <TemperaturePlot
          data={[fmSensorData]}
          layout={fmSensorLayout}
        />
      )}
    </div>
  );
}
