import React from 'react';
import { useContext } from 'react';
import { AlarmEmailStateStore } from '../../dataStore/user/AlarmEmailState';
import { KeycloakStore } from '../../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../../dataStore/user/Subscription';

export default function AlarmEmailInput() {
  const { state, dispatch } = useContext(AlarmEmailStateStore);
  const { checkPermissions, keycloak } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const permitted = checkPermissions(subscriptionData.currentTank, [
    'update-config'
  ]);

  const handleChange = event => {
    dispatch({
      type: 'UPDATE',
      value: { email: event.target.value }
    });
  };

  const handleSave = (event) => {
    keycloak.updateToken(30).then(() => {
      dispatch({
        type: 'SAVE',
        value: {
          keycloakToken: keycloak.token,
          tank: subscriptionData.currentTank,
          email: state.email
        }
      });
    });
  };

  return (
    <div>
      <label>
        <input
          disabled={!permitted}
          type="email"
          value={state.email}
          size={50}
          onChange={handleChange}
        />
      </label>
      <button
        disabled={!permitted}
        onClick={() => handleSave()}
        >Spara
      </button>
      <br/>
      <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{state.valid}</span>
    </div>
  );
}
