import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AlarmEmailInput from './AlarmEmailInput';
import PropTypes from 'prop-types';

AlarmNotificationControl.propTypes = {
  size: PropTypes.string
};

const useStyles = makeStyles({
  tableContainerNoScroll: {
    overflowX: 'unset'
  }
});

function createData(name, state) {
  return { name, state };
}

export default function AlarmNotificationControl(props) {
  const classes = useStyles();

  let rows = [];
  rows.push(createData('Vid larm, skicka email till:', <AlarmEmailInput />));

  return (
    <TableContainer
      component={Paper}
      className={classes.tableContainerNoScroll}
    >
      <Table aria-label="simple table" size={props.size}>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell scope="row">{row.name}</TableCell>
              <TableCell align="right" scope="row">
                {row.state}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
