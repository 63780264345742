import React, { useContext } from 'react';
import PhRangeSlider from './PhRangeSlider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PhRangeStore } from '../../dataStore/PhRange';
import { PhSensorStore } from '../../dataStore/PhSensor';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

PhAlarmControl.propTypes = {
  size: PropTypes.string
};

const useStyles = makeStyles({
  head: {
    fontWeight: 'bold'
  },
  alarmText: {
    color: 'red'
  },
  alarmIcon: { color: 'red', marginRight: 10, marginBottom: -6, marginTop: -6 },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '33vw',
    minWidth: 180,
    maxWidth: 330
  },
  min: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: 90
  },
  max: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 90
  },
  slider: {
    marginLeft: 15,
    marginRight: 15
  }
});

export default function PhAlarmControl(props) {
  const classes = useStyles();
  const { state } = useContext(PhRangeStore);
  const min = state.range[0];
  const max = state.range[1];
  const pH = useContext(PhSensorStore);
  const currentPh =
    pH.ph.length > 0
      ? pH.ph[pH.ph.length - 1].t
      : undefined;
  const alarmStatus = currentPh
    ? currentPh < min || currentPh > max
    : false;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" size={props.size}>
        <TableBody>
          <TableRow>
            <TableCell align="left">Nuvarande pH</TableCell>
            <TableCell align="right">
              <div className={alarmStatus ? classes.alarmText : ''}>
                {alarmStatus && <WarningIcon className={classes.alarmIcon} />}
                {currentPh && currentPh + ''}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Larmgränser för pH</TableCell>
            <TableCell align="right">
              <div className={classes.status}>
                <div className={classes.data}>
                  <div className={classes.min}>Min: {min}</div>
                  <div className={classes.max}>Max: {max}</div>
                </div>
                <br />
                <div className={classes.slider}>
                  <PhRangeSlider />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
