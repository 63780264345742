import React from 'react';
import AppBar from './AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
//import DeviceStateTable from './dashboardComponents/DeviceStateTable';
import TemperatureGraph from './dashboardComponents/TemperatureGraph';
import WaterTemperatureAlarmControl from './dashboardComponents/WaterTemperatureAlarmControl';
import PhAlarmControl from './dashboardComponents/PhAlarmControl';
import LightingScheduleControl from './dashboardComponents/LightingScheduleControl';
import AlarmNotificationControl from './dashboardComponents/AlarmNotificationControl';
import CameraControl from './dashboardComponents/CameraControl';
import ScrollTop from './ScrollTop';

export default function VerticalLayout() {
  return (
    <>
      <div id="back-to-top-anchor" />
      <AppBar marginBottom={80} />
      <CssBaseline />
      <Container maxWidth="md">
        <div style={{ marginBottom: '15px' }} />
        <CameraControl />
        <LightingScheduleControl />
        <br />
        <AlarmNotificationControl />
        {/* <DeviceStateTable />
        <br /> */}
        <br />
        <TemperatureGraph type="water" />
        <WaterTemperatureAlarmControl />
        <br />
        <TemperatureGraph type="air" />
        <br />
        <TemperatureGraph type="ph" />
        <PhAlarmControl size="small" />
        <br />
        <TemperatureGraph type="fm" />
        <br />
      </Container>
      <ScrollTop />
    </>
  );
}
