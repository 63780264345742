import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { PhRangeStore } from '../../dataStore/PhRange';
import { KeycloakStore } from '../../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../../dataStore/user/Subscription';

const useStyles = makeStyles({
  root: {
    width: '30vw',
    minWidth: 150,
    maxWidth: 300
  }
});

const marks = [
  {
    value: 5.5,
    label: '5.5'
  },
  {
    value: 6.5,
    label: '6.5'
  },
  {
    value: 7.5,
    label: '7.5'
  },
  {
    value: 8.5,
    label: '8.5'
  }
];

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider() {
  const classes = useStyles();
  const { state, dispatch } = useContext(PhRangeStore);
  const { checkPermissions, keycloak } = useContext(KeycloakStore);
  const { subscriptionData } = useContext(UserSubscriptionStore);
  const permitted = checkPermissions(subscriptionData.currentTank, [
    'update-config'
  ]);

  const handleChange = (event, newValue) => {
    if (state.range !== newValue) {
      dispatch({
        type: 'UPDATE',
        value: { range: newValue }
      });
    }
  };

  const handleChangeCommitted = (event, newValue) => {
    keycloak.updateToken(30).then(() => {
      dispatch({
        type: 'UPDATE_COMMIT',
        value: {
          keycloakToken: keycloak.token,
          tank: subscriptionData.currentTank,
          range: newValue
        }
      });
    });
  };

  return (
    <div className={classes.root}>
      <Slider
        disabled={!permitted}
        value={state.range}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={marks}
        min={5.5}
        max={8.5}
        step={0.1}
      />
    </div>
  );
}
