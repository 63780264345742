import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ViewCompact from '@material-ui/icons/ViewCompact';
import Photo from '@material-ui/icons/Photo';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Select from '@material-ui/core/Select';
import { KeycloakStore } from '../dataStore/user/KeycloakClient';
import { UserSubscriptionStore } from '../dataStore/user/Subscription';
import TankChooserDialog from './TankChooserDialog';
import LayoutChooserDialog from './LayoutChooserDialog';
import PropTypes from 'prop-types';

ButtonAppBar.propTypes = {
  marginBottom: PropTypes.number
};

const useStyles = makeStyles({
  title: {
    flexGrow: 1
  },
  drawerList: {
    width: 250
  },
  dropDown: {
    minWidth: 120,
    marginLeft: 30
  }
});

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const { keycloak } = useContext(KeycloakStore);
  const { subscriptionData, setSubscriptionData } = useContext(
    UserSubscriptionStore
  );
  const [tankChooserOpen, setTankChooserOpen] = useState(false);
  const [layoutChooserOpen, setLayoutChooserOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogInOut = () => {
    if (!keycloak.authenticated) {
      keycloak.login();
    } else {
      keycloak.logout();
    }
  };

  const handleSelectTank = e => {
    const value = e.target.value;
    if (value !== subscriptionData.currentTank) {
      localStorage.setItem('tank', value);
      setSubscriptionData(prevState => {
        return { ...prevState, currentTank: value, needsReload: true };
      });
    }
  };

  const handleTankChooserClose = value => {
    setTankChooserOpen(false);
    if (value !== subscriptionData.currentTank) {
      localStorage.setItem('tank', value);
      setSubscriptionData(prevState => {
        return { ...prevState, currentTank: value, needsReload: true };
      });
    }
  };

  const handleDrawerClickChooseLayout = () => {
    setDrawerOpen(false);
    setLayoutChooserOpen(true);
  };

  const handleDrawerClickChooseTank = () => {
    setDrawerOpen(false);
    setTankChooserOpen(true);
  };

  return (
    <div style={{ marginBottom: props.marginBottom }}>
      <AppBar position="fixed" color="default">
        <Toolbar>
          <IconButton
            size={window.innerWidth > 400 ? 'medium' : 'small'}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant={window.innerWidth > 400 ? 'h6' : 'h7'}
            className={classes.title}
          >
            RTE Iot Project
            {subscriptionData.currentTank && (
              <FormControl className={classes.dropDown}>
                <Select
                  value={subscriptionData.currentTank}
                  onChange={handleSelectTank}
                >
                  {subscriptionData.tanks.map(sub => (
                    <MenuItem value={sub} key={sub}>
                      {sub}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {subscriptionData.tanks && (
              <TankChooserDialog
                selectedValue={subscriptionData.currentTank}
                open={tankChooserOpen}
                onClose={handleTankChooserClose}
              />
            )}
          </Typography>
          <Button
            size={window.innerWidth > 400 ? 'large' : 'small'}
            color="inherit"
            onClick={handleLogInOut}
          >
            {keycloak.authenticated ? 'Logga ut' : 'Logga in'}
          </Button>
        </Toolbar>
      </AppBar>
      <LayoutChooserDialog
        open={layoutChooserOpen}
        onClose={() => setLayoutChooserOpen(false)}
      />
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerList}>
          <List>
            {subscriptionData.tanks && (
              <ListItem
                button
                key="change-tank"
                onClick={handleDrawerClickChooseTank}
              >
                <ListItemIcon>
                  <Photo />
                </ListItemIcon>
                <ListItemText primary="Välj tank..." />
              </ListItem>
            )}
            <ListItem
              button
              key="change-layout"
              onClick={handleDrawerClickChooseLayout}
            >
              <ListItemIcon>
                <ViewCompact />
              </ListItemIcon>
              <ListItemText primary="Välj layout..." />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}
