import React from 'react';
import './App.css';
import KeycloakProvider from './dataStore/user/KeycloakClient';
import UserSubscriptionProvider from './dataStore/user/Subscription';
import WebSocketDataProvider from './dataStore/WebSocketData';
import WaterTemperatureProvider from './dataStore/WaterTemperature';
import AirTemperatureProvider from './dataStore/AirTemperature';
import PhSensorProvider from './dataStore/PhSensor';
import FmSensorProvider from './dataStore/FmSensor';
import WaterTemperatureRangeProvider from './dataStore/WaterTemperatureRange';
import PhRangeProvider from './dataStore/PhRange';
import LightingScheduleProvider from './dataStore/lighting/Schedule';
import LightingStateProvider from './dataStore/lighting/State';
import LightingManualControlStateProvider from './dataStore/lighting/ManualControlState';
import AlarmEmailStateProvider from './dataStore/user/AlarmEmailState';
import PictureProvider from './dataStore/camera/Picture';
import LayoutSelector from './components/LayoutSelector.js';

function App() {
  return (
    <div className="App">
      <KeycloakProvider>
        <UserSubscriptionProvider>
          <WebSocketDataProvider>
            <WaterTemperatureProvider>
              <AirTemperatureProvider>
                <PhSensorProvider>
                  <FmSensorProvider>
                    <WaterTemperatureRangeProvider>
                      <PhRangeProvider>
                        <LightingScheduleProvider>
                          <LightingStateProvider>
                            <LightingManualControlStateProvider>
                              <PictureProvider>
                                <AlarmEmailStateProvider>
                                  <LayoutSelector />
                                </AlarmEmailStateProvider>
                              </PictureProvider>
                            </LightingManualControlStateProvider>
                          </LightingStateProvider>
                        </LightingScheduleProvider>
                      </PhRangeProvider>
                    </WaterTemperatureRangeProvider>
                  </FmSensorProvider>
                </PhSensorProvider>
              </AirTemperatureProvider>
            </WaterTemperatureProvider>
          </WebSocketDataProvider>
        </UserSubscriptionProvider>
      </KeycloakProvider>
    </div>
  );
}

export default App;
